.btn {
  border: none;
  border-radius: 15px;
  box-shadow: 0 0 8px rgb(207, 207, 207);
}

.btn:hover {
  border: none;
  padding: 15px;
  border-radius: 15px;
  box-shadow: inset 0 0 8px #f9f8fc;
}

#foot{
  position: relative;
  top: 15px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: none;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 0 8px #d9d4e7;
  width: 200px;
  height: 65px;
  background-color: #d9d4e7;
  animation-duration: 5s;
}

.button-os {
  position: absolute;
  width: 200px;
  height: 65px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  margin: auto;
  background-color: #f9f8fc;
  transition: background-color 2.2s;
  transition-duration: 3s;
  transition: 1.5s;
}

.button-os:hover {
  position: absolute;
  top: 30px;
  background-color: #fec7d7;
}

.button-os a {
  font-size: 20px;
  display: block;
  text-decoration: none;
  color: #0e172c;
  transition: 1.5s;
}

.button-os a:hover {
  letter-spacing: 5px;
}

@font-face {
  font-family: Fieldwork10;
  src: url(./assets//fonts//Fieldwork10-GeoRegular.otf);
}
@font-face {
  font-family: TungstenRounded;
  src: url(./assets//fonts//TungstenRounded-Book.ttf);
}

.font-inter {
  font-family: "Inter";
}

.font-tungstenrounded {
  font-family: TungstenRounded;
}

.font-fieldwork10 {
  font-family: Fieldwork10;
}

.cardContainer {
  width: 100%;
  max-width: 460px;
  height: 60vh;
}

.card {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 260px;
  height: 300px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.ScratchCard__Result {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* .bg-image {
  background-image: url(./assets/how\ to\ play.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
} */

.shadow1 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.btn-3d {
 box-shadow: 6px 8px 2px 0px rgba(0, 0, 0, 0.2);
}

.animation-reverse {
  height: 100%;
  bottom: 0;
  left: 0;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
  transform: scaleX(0);
}
.animation-reverse-1 {
  transform: scaleX(1);
}

.ql-align-center {
  text-align: center;
}

.gradient-text {
  background: linear-gradient(to right, #4CC8E0, #4DDF69);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text2 {
  background: linear-gradient(to right, #FA7051, #FC3977);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.lazy-load-image-loaded {
  height: inherit;
}

canvas {
  overflow: hidden;
}

.prose h1 {
  font-size: 2.5vh;
}

.prose h3 {
  font-size: 2.35vh;
}

.prose h3 {
  font-size: 2.2vh;
}

.prose li {
  font-size: 1.8vh;
}